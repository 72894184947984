
import { ICountry } from 'types/country_types'
import { defineComponent } from 'vue'
import { AlertCategory, QueryFilter, SurcoucheV2Filters, SurcoucheV2Query } from '../../../types/surcouche_v2_types'
// import { fetchAlertCategories, fetchCountries, parseCountryResponse } from './surcoucheV2utils'

export default defineComponent({
  name: 'ExpansionsFilters',
  props: {
    value: {
      required: false
    }
  },
  data: function () {
    return {
      filters: this.value as QueryFilter,
      baselineItems: ['AB_TEST', 'BEFORE_AFTER', 'OTHER'],
      retry: [
        { text: 'On', value: 'On' },
        { text: 'Off', value: 'Off' }
      ],
      fastRetry: [
        { text: 'On', value: 'On' },
        { text: 'Off', value: 'Off' }
      ],
      optiAuto: [
        { text: 'On', value: 'On' },
        { text: 'Off', value: 'Off' }
      ],
      isProactif: [
        { text: 'Yes', value: 'On' },
        { text: 'No', value: 'Off' }
      ],
      stratLvlItems: [
        {
          text: 'Standard & above (default)',
          value: ['standard']
        },
        {
          text: 'Under monitoring',
          value: ['under_monitoring']
        }
      ]
    }
  },
  async mounted () {
    this.filters = this.value
  },
  methods: {
    setAlertCategoriesText (value: AlertCategory) {
      const valueWithSpace = value.name.replace(/([A-Z])/g, ' $1').trim()
      return valueWithSpace[0].toUpperCase() + valueWithSpace.slice(1)
    }
  },
  computed: {
    countries () : Array<ICountry> {
      return this.$store.getters.getSurcoucheV2CountriesItems
    },
    alertCategories () : Array<AlertCategory> {
      return this.$store.getters.getSurcoucheV2AlertCategoriesItems
    }
  },
  watch: {
    filters: {
      deep: true,
      immediate: false,
      handler: function (value: QueryFilter) {
        this.$emit('input', value)
      }
    },
    value: {
      deep: true,
      immediate: false,
      handler: function (value: SurcoucheV2Filters) {
        this.filters = this.value
      }
    }
  }
})
